(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/format/assets/javascripts/format.js') >= 0) return;  svs.modules.push('/components/utils/format/assets/javascripts/format.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  svs.utils = svs.utils || {};
  svs.utils.format = svs.utils.format || {};

  svs.utils.format.Currency = function(num, alwaysDecimals) {
    var isFloat; var n; var pos; var returnString; var str; var strEnd;

    if (num === null || typeof num === 'undefined') {
      return null;
    }

    try {
      if (typeof num === 'string') {
        num = num.replace(/\s/g, '');
        num = num.replace(',', '.');
        n = parseFloat(num);
        n = String(n);
      } else {
        n = String(num);
      }

      isFloat = n.indexOf('.') !== -1;

      if (isFloat) {
        str = n.slice(0, n.indexOf('.'));
        strEnd = n.substr(n.indexOf('.') + 1, n.length);

        if (strEnd.length === 1) {
          strEnd += '0';
        }
      } else {
        str = n;
      }

      pos = str.length - 3;
      while (pos > 0) {
        str = str.slice(0, pos) + ' ' + str.slice(pos, str.length);
        pos -= 3;
      }

      if (isFloat) {
        returnString = str + ',' + strEnd;
        returnString = returnString.substr(0, returnString.indexOf(',') + 3);
      } else {
        if (alwaysDecimals) {
          str += ',00';
        }
        returnString = str;
      }
    } catch (e) {
      num = num ? num : '';
      returnString = String(num);
    }

    return returnString;
  };

  svs.utils.format.Odds = function(num) {
    return svs.utils.format.Currency(num, true);
  };

  svs.utils.format.CurrencyInMillions = function(n) {
    if (n === undefined) {
      return null;
    }

    if (typeof n === 'string') {
      n = n.replace(/\s/g, '');
      n = n.replace(',', '.');
      n = parseFloat(n);
    }

    var nm = n / 1000000;
    nm = nm.toString();
    nm = nm.replace('.', ',');
    if (nm.indexOf(',') !== -1) {
      nm = nm.substr(0, nm.indexOf(',') + 2);
    }

    if(nm.length > 3) {
      nm = nm.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    }

    return nm;
  };

  svs.utils.format.round50 = function(num) {
    return Math.round(num * 2) / 2;
  };

  svs.utils.format.roundToDecimals = function(num, decimals) {
    return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
  };
  svs.utils.format.CurrencyAsJupiterString = function(num) {
    if (num === undefined) {
      return null;
    }

    num = num.toString();
    num = num.replace(/\s/g, '');
    num = num.replace('.', ',');
    if (num.indexOf(',') < 0) {
      num += ',00';
    }
    if (num.indexOf(',') === num.length - 2) {
      num += '0';
    }
    return num;
  };

  svs.utils.format.CurrencyFromJupiterString = function(val) {
    if (val === undefined) {
      return null;
    }

    if (typeof val === 'number') {
      val = val.toString();
    }

    val = val.replace(/\s/g, '');
    val = val.replace(',', '.');
    if (isNaN(val)) {
      return val;
    }
    return parseFloat(val);
  };

  svs.utils.format.formatValueToCommaStr = function(
    value,
    decimals,
    addDecimals
  ) {
    var tempValue = value;
    if (!_.isString(value)) {
      return null;
    }

    tempValue = tempValue.replace(',', '.');
    if (tempValue.indexOf('.') > -1 || addDecimals) {
      tempValue = (Math.round(tempValue * 100) / 100).toFixed(decimals);
      tempValue = tempValue.replace('.', ',');
    }

    return tempValue;
  };

  svs.utils.format.getPercentageFromDecimalPercentage = function(
    decimalPercentage,
    options
  ) {
    var float; var percentage;

    options = options || {};
    options.returnNumber = options.returnNumber || false;
    options.numberOfDecimals = options.numberOfDecimals || 2;

    if (
      Object.prototype.toString.call(decimalPercentage) !== '[object String]'
    ) {
      return options.returnNumber ? 0 : '';
    }

    decimalPercentage = decimalPercentage.replace(',', '.');
    float = parseFloat(decimalPercentage);
    percentage = float * 100;
    percentage = percentage.toFixed(options.numberOfDecimals);

    return options.returnNumber ?
      parseFloat(percentage) :
      new String(percentage).replace('.', ',');
  };

  svs.utils.format.capitalize = function(str, lowercaseRest) {
    if (str === null) {
      return '';
    }
    str = String(str);
    var remainingChars = !lowercaseRest ?
      str.slice(1) :
      str.slice(1).toLowerCase();

    return str.charAt(0).toUpperCase() + remainingChars;
  };

  svs.utils.format.abbreviatePlayerName = function(firstName, lastName, name) {
    var ret = '';

    if (_.isString(firstName) && _.isString(lastName)) {
      firstName = firstName.substring(0, 1);
      ret = firstName + '. ' + lastName;
    } else {
      if (_.isString(name)) {
        var names = name.split(' ');

        if (names.length >= 2) {
          names[0] = names[0].substring(0, 1);
        }

        if (names[0] && names[1]) {
          ret = names[0] + '. ' + names[1];
        } else {
          ret = names;
        }
      }
    }

    return ret;
  };

  svs.utils.format.numberAsString = function(number, initialUppercase) {
    var numberString = [
      'Noll',
      'Ett',
      'Två',
      'Tre',
      'Fyra',
      'Fem',
      'Sex',
      'Sju',
      'Åtta',
      'Nio',
      'Tio',
      'Elva',
      'Tolv'
    ][number];
    numberString = numberString ? numberString : String(number);

    if (!initialUppercase) {
      numberString = numberString.toLowerCase();
    }

    return numberString;
  };

  svs.utils.format.safeString = function(string) {
    if (!string) {
      return '';
    }
    let safeString = string
      .replace(/^[.\s]+/g, '')
      .replace(/[?!"()&€%]/g, '')
      .replace(/[@]/g, '_a_')
      .replace(/[åäáà]/gi, 'a')
      .replace(/[é]/gi, 'e')
      .replace(/[ö]/gi, 'o')
      .replace(/[^a-z0-9._-]/gi, '-')
      .replace(/_/g, '-')
      .toLowerCase();
    return safeString;
  };

  if (typeof exports === 'object') {
    module.exports = svs.utils.format;
  }
})(svs);


 })(window);