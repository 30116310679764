(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/format/assets/javascripts/helpers/handlebarhelper.js') >= 0) return;  svs.modules.push('/components/utils/format/assets/javascripts/helpers/handlebarhelper.js');

(function() {
  'use strict';

  var isServerSide = typeof exports === 'object';
  var trinidad;

  if (svs.isServer) {
    trinidad = require('trinidad-core').core;
    registerHelpers(require('hbs'));
  } else {
    registerHelpers(Handlebars);
  }

  function registerHelpers(hbs) {
    hbs.registerHelper('formatCurrencyMilj', function(number) {
      if (typeof number === 'string') {
        number = svs.utils.format.CurrencyFromJupiterString(number);
      }
      return number > 999999 ?
        svs.utils.format.CurrencyInMillions(number) + ' milj.' :
        svs.utils.format.Currency(number, false);
    });

    hbs.registerHelper('formatCurrency', function(number) {
      var format;
      if (isServerSide) {
        format = trinidad.components.require('utils', 'format').api;
      } else {
        format = svs.utils.format;
      }
      if (typeof number === 'function') {
        return format.Currency(number.call(this));
      }
      return format.Currency(number);
    });

    hbs.registerHelper('capitalize', function(text) {
      if (text) {
        text = text.substr(0, 1).toUpperCase() + text.substr(1);
      }
      return text;
    });

    hbs.registerHelper('toLowerCase', function(value) {
      return value && typeof value === 'string' ? value.toLowerCase() : '';
    });

    hbs.registerHelper('trim', function(value) {
      return String(value).trim();
    });

    hbs.registerHelper('formatOdds', function(number) {
      return svs.utils.format.Odds(number);
    });

    hbs.registerHelper('removeHTML', function(text) {
      return text && typeof text.replace === 'function' ?
        text.replace(/<(?:.|\n)*?>/gm, '') :
        '';
    });
  }
})();


 })(window);